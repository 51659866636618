import { useState } from "react";

export const useConstants_1 = () => {

const [inputTableVar,setInputTableVar]=useState([
    {Variable:"الطرف الأول"},
    
  
  
  
  
    
])

return{
    inputTableVar
}
}